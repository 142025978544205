import React from "react";
import PropTypes from "prop-types";
import styled from '@emotion/styled'

const YoutubeWrapper = styled.div`
  max-width: 853px;
  display: block;
  margin: 0 auto;
  
  > div {
    width: 100%;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`

const YoutubeEmbed = ({ embedId }) => (
  <YoutubeWrapper>
    <div>
      <VideoContainer>
        <iframe
          src={`https://www.youtube.com/embed/${embedId}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="David Jarre - Vivement dimanche"
        />
      </VideoContainer>
    </div>
  </YoutubeWrapper>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;