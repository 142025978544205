import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import YoutubeEmbed from '../components/YoutubeEmbed'

const Container = styled.main`
  width: 100%;
  padding: 20px;
  background: #fff;
`;

const Section = styled.section`
  max-width: 800px;
  margin: 0 auto;
  background: #fff;

  img {
    max-width: 100%;
    display: block;
    &.float {
      max-width: 50%;
      float: left;
      margin: 0 15px 1px 0;
    }
  }

  p {
    text-align: justify;
  }

  h4 {
    text-align: center;
    padding: 0;
    margin: 0;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      font-size: 14px;
    }
  }

  h3 {
    padding: 0;
  }

  h2, h3 {
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      font-size: 21px;
    }
  }

  &.invert {
    background: none;
    h3, h4, p, a {
      color: #fff;
    }
  }
`;

const Presskit = (props) => {
  
  return (
    <React.Fragment>
      <Container>
        <Section>
          <img className="float" src={props.data.main.publicURL} alt="David Jarre - Mosaic" />
          <h2><strong>Un univers envoûtant à la frontière du réel</strong></h2>
        </Section>
        <Section>
          <p>David Jarre présente Mosaic, un spectacle où magie, mentalisme et illusion s’entrelacent et se confondent. Admirateur à ces débuts du célèbre David Copperfield, le David fait partie de ce cercle fermé de talents singuliers, imprévisibles, qu'on ne peut classer ou mettre dans une case.</p>
          <p>Mosaic, le premier one-man-show de l’artiste franco-britannique transporte le public dans un univers fascinant, là où les illusions défient toute logique, laissant le spectateur bluffé et émerveillé. Grâce à son talent de magicien et sa personnalité sur scène, il crée une atmosphère captivante où l’impossible devient possible.</p>
          <p>Dans une atmosphère intime et saisissante, l’artiste nous transporte vers un monde, fascinant, dans l’inconnu poétique où l’esprit humain est mis à l’épreuve et où la magie devient une réalité à part entière. Sa maîtrise du mentalisme impressionne. Il semble lire dans les pensées de ses spectateurs et prédire des choix apparemment aléatoires.</p>
          <p>Magicien et mentaliste reconnu depuis 20 ans en France et à l’international, David Jarre a travaillé notamment pour la famille Royale de Grande-Bretagne. Depuis 2022, il fait également des apparitions télévisées récurrentes, notamment dans l'émission "Vivement Dimanche" animée par Michel Drucker et « C à Vous » animée par Anne Elizabeth Lemoine. Il a aussi été appelé pour de nombreux événements privés pour des célébrités telles que Roger Federer, Madonna, Sting, Elton John, Adèle, Lady Gaga et bien d’autres. Des milliers de performances à travers le monde lui ont permis de bâtir sa réputation comme l'un des grands magiciens d'aujourd'hui.</p>
        </Section>
      </Container>

      <Container style={{ 
        background: `url(${props.data.background.publicURL})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}>
        <Section className="invert">
          <h3><strong>Le 9 mars à 20h30</strong><br />Salle Jean-Jacques Gautier<br />Route du Vallon 1, Chêne-Bougeries</h3>
          <h4>Infos événement : <a rel="noreferrer" href="https://www.chene-bougeries.ch/evenements" target="_blank">www.chene-bougeries.ch/evenements</a></h4>
          <h4>Retrouvez toutes les infos sur <a rel="noreferrer" href="https://www.davidjarre.com" target="_blank">www.davidjarre.com</a></h4>
        </Section>
        <Section style={{ marginTop: "100px" }}>
          <YoutubeEmbed embedId="uIFMoWaaE1s" />
        </Section>
        <Section style={{ marginTop: "100px" }}>
          <img src={props.data.main.publicURL} alt="David Jarre - Mosaic" />
        </Section>
      </Container>
    </React.Fragment>
  )
}

export default Presskit
export const pageQuery = graphql`
  query Presskit {
    background: file(relativePath: {eq: "background.jpg"}) {
      publicURL
    }
    main: file(relativePath: {eq: "djfly.jpg"}) {
      publicURL
    }
  }
`